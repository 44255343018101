import type { Mutation, MutationSendIncomingGoodsMailArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_LATEST_DELIVERY_NUMBERS } from './gql/getLatestDeliveryNumbers';
import { SEND_INCOMING_GOODS_MAIL } from './gql/sendIncomingGoodsMail';

type MutationDataType = Pick<Mutation, 'sendIncomingGoodsMail'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('sendIncomingGoodsMail');

const refetchQueries = [GET_LATEST_DELIVERY_NUMBERS];

export const useSendIncomingGoodsMail = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [sendIncomingGoodsMail, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSendIncomingGoodsMailArgs
    >(SEND_INCOMING_GOODS_MAIL, {
        refetchQueries,
        onError
    });

    const outgoingSendIncomingGoodsMail = useCallback(
        (incomingGoodsInput: MutationSendIncomingGoodsMailArgs['incomingGoodsInput']) => {
            sendIncomingGoodsMail({ variables: { incomingGoodsInput } });
        },
        [sendIncomingGoodsMail]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        sendIncomingGoodsMail: outgoingSendIncomingGoodsMail,
        loading,
        response
    };
};
