import { gql } from '@apollo/client';

export const INCOMING_GOODS = gql`
    query IncomingGoods($deliveryNoteNumber: String!) {
        incomingGoods(deliveryNoteNumber: $deliveryNoteNumber) {
            id
            positionNumber
            name
            positionsText
            sku
            quantity
            quantityUnit
            image
            orderNumber
            incomingGoodState
        }
    }
`;
