import { userHasPermissions } from '@ab-core-functions/user-permission';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { useIsFeatureVisible } from '@ab-core/functions';
import { isShopApp } from '@ab-core/functions/isShopApp';
import { useIsMobile } from '@ab-core/functions/useIsMobile';
import type { FC } from 'react';
import React from 'react';
import { NavigationElement } from './navigation';
import { TitleElement } from './title';
import type { ContentItem } from './types';
import { navigation } from './utils/elementsObjects';

type ShopElementsProps = {
    pathName: string;
    onClose?: () => void;
};

export const ShopElements: FC<ShopElementsProps> = (props) => {
    const { pathName, onClose } = props;
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const isApp = isShopApp();

    const filterNavigation = navigation.map((item) => ({
        ...item,
        content: item.content.filter((content: ContentItem) => {
            const hasPermission = !content.permissionName || userHasPermissions(content.permissionName);
            const isFeatureVisible = useIsFeatureVisible(content.featureName);
            const visibleOnViewport = content.onlyMobile ? isMobile || isApp : true;

            return hasPermission && isFeatureVisible && visibleOnViewport;
        })
    }));

    return (
        <div className="my-medium">
            {filterNavigation?.map(
                (section, index) =>
                    section.content.length > 0 && (
                        <React.Fragment key={index}>
                            <TitleElement headline={section.headline} />
                            <NavigationElement content={section.content} pathName={pathName} onClose={onClose} />
                        </React.Fragment>
                    )
            )}
        </div>
    );
};
