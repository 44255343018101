import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Refresh: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.70611 10.218C1.70611 10.5631 1.98594 10.843 2.33111 10.843C2.67629 10.843 2.95611 10.5631 2.95611 10.218H1.70611ZM5.12646 3.69551L5.55174 4.1535L5.56022 4.14563L5.5684 4.13745L5.12646 3.69551ZM18.3045 16.8735L17.8626 16.4316L17.8626 16.4316L18.3045 16.8735ZM5.71756 16.4483C5.48269 16.1953 5.08723 16.1807 4.83429 16.4155C4.58134 16.6504 4.5667 17.0459 4.80157 17.2988L5.71756 16.4483ZM4.10417 9.99435C4.34825 9.75028 4.34825 9.35455 4.10417 9.11047C3.86009 8.86639 3.46437 8.86639 3.22029 9.11047L4.10417 9.99435ZM2.33111 10.8835L1.88917 11.3255C2.13325 11.5695 2.52898 11.5695 2.77306 11.3255L2.33111 10.8835ZM1.44194 9.11047C1.19786 8.86639 0.802136 8.86639 0.558058 9.11047C0.313981 9.35455 0.313981 9.75028 0.558058 9.99435L1.44194 9.11047ZM2.95611 10.218C2.95611 7.98155 3.82558 5.75636 5.55174 4.1535L4.70117 3.23751C2.70021 5.09555 1.70611 7.66238 1.70611 10.218H2.95611ZM5.5684 4.13745C8.91833 0.787517 14.5126 0.787517 17.8626 4.13745L18.7464 3.25357C14.9083 -0.584522 8.5226 -0.584522 4.68451 3.25357L5.5684 4.13745ZM17.8626 4.13745C21.2125 7.48738 21.2125 13.0817 17.8626 16.4316L18.7464 17.3155C22.5845 13.4774 22.5845 7.09165 18.7464 3.25357L17.8626 4.13745ZM17.8626 16.4316C16.1142 18.18 13.8763 19.0439 11.782 19.0439V20.2939C14.2135 20.2939 16.7677 19.2942 18.7464 17.3155L17.8626 16.4316ZM11.782 19.0439C9.54561 19.0439 7.32042 18.1744 5.71756 16.4483L4.80157 17.2988C6.65961 19.2998 9.22644 20.2939 11.782 20.2939V19.0439ZM3.22029 9.11047L1.88917 10.4416L2.77306 11.3255L4.10417 9.99435L3.22029 9.11047ZM2.77306 10.4416L1.44194 9.11047L0.558058 9.99435L1.88917 11.3255L2.77306 10.4416Z"
                fill={color}
            />
        </SVG>
    );
};
