import type { Mutation, MutationSearchTrackingRecommendationClickArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_TRACKING_RECOMMENDATION_CLICK } from './gql/searchTrackingRecommendationClick';

type MutationDataType = Pick<Mutation, 'searchTrackingRecommendationClick'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('searchTrackingRecommendationClick');

export const useSearchTrackingRecommendationClick = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [searchTrackingRecommendationClick, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSearchTrackingRecommendationClickArgs
    >(SEARCH_TRACKING_RECOMMENDATION_CLICK, {
        onError
    });

    const outgoingSearchTrackingRecommendationClick = useCallback(
        (
            trackingRecommendationClickPos: MutationSearchTrackingRecommendationClickArgs['trackingRecommendationClickPos']
        ) => {
            if (trackingRecommendationClickPos.id === trackingRecommendationClickPos.mainId) {
                return;
            }

            searchTrackingRecommendationClick({ variables: { trackingRecommendationClickPos } });
        },
        [searchTrackingRecommendationClick]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        searchTrackingRecommendationClick: outgoingSearchTrackingRecommendationClick,
        loading,
        response
    };
};
