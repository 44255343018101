import type { FactFinderFacet, FactFinderFacetElement } from '@ab-core/graphql/dist';
import { convertName, encodeQueryValue } from './nameConverter';

const SLIDER_QUERY_PLACEHOLDER = 'SLIDER-QUERY-VALUE';

export const getSliderQuery = (query: string, minValue: number, maxValue: number) => {
    return query.replace(SLIDER_QUERY_PLACEHOLDER, `${minValue}, ${maxValue}`);
};

export type MappedFacetType = {
    name: string;
    query: string;
    elements: Array<FacetElementType>;
};

export type FacetElementType = {
    selected: boolean;
    text: React.ReactNode;
    totalHits: number;
    query: string;
    parentText: string;
    disabled: boolean;
    associatedFieldName: string;
    level: number;
    absoluteMaxValue: number;
    absoluteMinValue: number;
    selectedMaxValue: number;
    selectedMinValue: number;
    unit?: string;
    filterType: FILTER_TYPE;
};

enum SELECTED_TYPE {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
    IMPLICIT = 'IMPLICIT'
}

export enum FILTER_TYPE {
    MULTISELECT = 'MULTISELECT',
    TREE = 'TREE',
    SLIDER = 'SLIDER'
}

export const getMappedFacets = (factFinderFacets: Array<FactFinderFacet> = []): Array<MappedFacetType> => {
    if (factFinderFacets.length === 0) {
        return [];
    }

    const mappedFilters: Array<MappedFacetType> = [];
    factFinderFacets.forEach((facet) => {
        const elements: Array<FacetElementType> = [];

        facet.selectedElements?.forEach((element) => {
            elements.push(getFacetElement(facet, element));
        });
        facet.elements?.forEach((element) => {
            elements.push(getFacetElement(facet, element));
        });

        const filteredElements = elements.filter((element) => {
            if (element.filterType !== FILTER_TYPE.SLIDER) {
                return true;
            }

            if (element.absoluteMinValue !== element.absoluteMaxValue) {
                return true;
            }

            return false;
        });

        if (filteredElements.length === 0) {
            return;
        }

        mappedFilters.push({ name: facet.name, query: facet.associatedFieldName || '', elements: filteredElements });
    });

    return mappedFilters;
};

const getFilterType = (filter?: string): FILTER_TYPE => {
    if (!filter) {
        return FILTER_TYPE.MULTISELECT;
    }

    if (!Object.values(FILTER_TYPE).includes(filter as FILTER_TYPE)) {
        return FILTER_TYPE.MULTISELECT;
    }

    return FILTER_TYPE[filter as FILTER_TYPE];
};

const getFacetElement = (
    factFinderFacet: FactFinderFacet,
    factFinderFacetElement: FactFinderFacetElement
): FacetElementType => {
    const selected =
        factFinderFacetElement.selected === SELECTED_TYPE.TRUE ||
        factFinderFacetElement.selected === SELECTED_TYPE.IMPLICIT;
    const disabled = factFinderFacetElement.selected === SELECTED_TYPE.IMPLICIT;
    const filterType = getFilterType(factFinderFacet.filterStyle);
    const associatedFieldName = factFinderFacet.associatedFieldName || '';
    const level = factFinderFacetElement.clusterLevel || 0;
    const factFinderFacetElementText = encodeQueryValue(factFinderFacetElement.text);

    let query = `${associatedFieldName}:`;

    if (filterType === FILTER_TYPE.TREE && level > 0) {
        const searchParams = factFinderFacetElement.searchParams?.filters?.find((f) => f.name === associatedFieldName);
        searchParams?.values?.forEach((val) => {
            if (val.value) {
                const length = val.value.length;
                val.value.forEach((v, index) => {
                    query += encodeQueryValue(v);

                    if (length > index + 1) {
                        query += '/';
                    }
                });

                if (factFinderFacetElement.selected === SELECTED_TYPE.IMPLICIT) {
                    query += `/${factFinderFacetElementText}`;
                }
            }
        });
    }

    if (filterType === FILTER_TYPE.TREE && level === 0) {
        query += factFinderFacetElementText;
    }

    if (filterType === FILTER_TYPE.MULTISELECT) {
        query += factFinderFacetElement.text;
    }

    if (filterType === FILTER_TYPE.SLIDER) {
        query += `[${SLIDER_QUERY_PLACEHOLDER}]`;
    }

    const absoluteMinValue = Math.floor(factFinderFacetElement.absoluteMinValue || 0);
    const absoluteMaxValue = Math.ceil(factFinderFacetElement.absoluteMaxValue || 0);
    const selectedMinValue = Math.max(absoluteMinValue, Math.floor(factFinderFacetElement.selectedMinValue || 0));
    const selectedMaxValue = Math.min(absoluteMaxValue, Math.ceil(factFinderFacetElement.selectedMaxValue || 0));

    return {
        selected,
        disabled,
        query,
        level,
        filterType,
        associatedFieldName,
        absoluteMinValue,
        absoluteMaxValue,
        selectedMinValue,
        selectedMaxValue,
        text: convertName(factFinderFacetElement.text),
        totalHits: factFinderFacetElement.totalHits || 0,
        parentText: factFinderFacet.name,
        unit: factFinderFacet.unit
    };
};
