import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Five: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
                stroke={color}
            />
            <path
                d="M8.32 5.76L8.26 8.68C8.66 8.6 9.08 8.56 9.62 8.56C12.78 8.56 14.16 10.08 14.16 12.28C14.16 14.56 12.66 16.24 9.66 16.24C6.94 16.24 5.68 14.58 5.68 14.58L6.76 13.14C6.76 13.14 7.82 14.42 9.62 14.42C11.18 14.42 12.08 13.62 12.08 12.3C12.08 11.1 11.16 10.26 9.26 10.26C7.64 10.26 6.64 10.7 6.62 10.72L6.36 10.52L6.54 4H13.5V5.76H8.32Z"
                fill={color}
            />
        </SVG>
    );
};
