import type { Query, QueryProjectOfferArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { PROJECT_OFFER } from './gql/projectOffer';

type QueryDataType = Pick<Query, 'projectOffer'>;
type OutgoingDataType = QueryDataType['projectOffer'];

const onError = getErrorLoggerFunction('projectOffer');

export const useProjectOffer = (projectOfferId = '') => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = !projectOfferId;
    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryProjectOfferArgs>(PROJECT_OFFER, {
        variables: {
            projectOfferId
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.projectOffer);
        }
    }, [data]);

    return {
        projectOffer: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
