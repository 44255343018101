import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import Text, { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { FooterElementWrapper } from './index.styled';

type FooterElementType = WithTestId & {
    iconName: IconProps['name'];
    onClick: () => void;
    content: string;
};

export const FooterElement: FC<FooterElementType> = (props) => {
    const { iconName, onClick, content, testId } = props;

    return (
        <FooterElementWrapper onClick={onClick} {...getTestIdProp(testId)}>
            <Icon name={iconName} />
            <Text type={FontTypes.Caption} content={content} />
        </FooterElementWrapper>
    );
};
