import type { Query, QueryOfferArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { PROJECT_OFFERS } from './gql/projectOffers';

type QueryDataType = Pick<Query, 'projectOffers'>;
type OutgoingDataType = QueryDataType['projectOffers'];

const onError = getErrorLoggerFunction('projectOffers');

export const useProjectOffers = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryOfferArgs>(PROJECT_OFFERS, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.projectOffers);
        }
    }, [data]);

    return {
        projectOffers: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
