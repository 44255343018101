import type { Mutation, MutationDeleteOrderConfirmationRecipientArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_ORDER_CONFIRMATION_RECIPIENT } from './gql/deleteOrderConfirmationRecipient';
import { GET_ORDER_CONFIRMATION_RECIPIENTS } from './gql/getOrderConfirmationRecipients';

type MutationDataType = Pick<Mutation, 'deleteOrderConfirmationRecipient'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteOrderConfirmationRecipient');

const refetchQueries = [GET_ORDER_CONFIRMATION_RECIPIENTS];

export const useDeleteOrderConfirmationRecipient = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteOrderConfirmationRecipient, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationDeleteOrderConfirmationRecipientArgs
    >(DELETE_ORDER_CONFIRMATION_RECIPIENT, {
        onError,
        refetchQueries
    });

    const outgoingDeleteOrderConfirmationRecipient = useCallback(
        (id: MutationDeleteOrderConfirmationRecipientArgs['id']) => {
            deleteOrderConfirmationRecipient({ variables: { id } });
        },
        [deleteOrderConfirmationRecipient]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteOrderConfirmationRecipient: outgoingDeleteOrderConfirmationRecipient,
        loading,
        response
    };
};
