import { BreakpointsMaxQuery } from '@ab-core/breakpoints';
import Footer from '@ab-core/footer/src/footer';
import { FooterNavigation } from '@ab-core/footernavigation';
import { useMediaQuery } from '@ab-core/functions';
import { Header } from '@ab-core/header';
import { useHasMounted } from '@ab-core/hooks';
import type { WrapperProps } from '@ab-core/seo';
import { Wrapper } from '@ab-core/seo';
import { ShopElements } from '@ab-core/shop-navigation';
import { Sidebar } from '@ab-core/sidebar';
import Spacer from '@ab-core/spacer';
import type { WithChildren } from '@ab-core/utils/types';
import { inject } from '@vercel/analytics';
import type { PageRendererProps } from 'gatsby';
import type { FC } from 'react';
import React from 'react';

type WrapPageElementProps = WithChildren &
    PageRendererProps & { hasNavigation?: boolean; metaData?: WrapperProps['metaData'] };

export const WrapPageElement: FC<WrapPageElementProps> = (props) => {
    const hasMounted = useHasMounted();
    const { children, hasNavigation, location, metaData } = props;
    const isMobile = useMediaQuery(BreakpointsMaxQuery.lg);

    if (!hasMounted) {
        return null;
    }

    inject();

    return (
        <>
            <Header location={location} />
            {hasNavigation ? (
                <Wrapper slug="onlineshop" metaData={metaData}>
                    <Sidebar sidebarContent={<ShopElements pathName={location?.pathname} />}>
                        <Spacer size="xs" />
                        {children}
                    </Sidebar>
                </Wrapper>
            ) : (
                <>{children}</>
            )}
            <Footer />
            {isMobile && <FooterNavigation />}
        </>
    );
};
