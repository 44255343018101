import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_LATEST_DELIVERY_NUMBERS } from './gql/getLatestDeliveryNumbers';

type QueryDataType = Pick<Query, 'getLatestDeliveryNumbers'>;
type OutgoingDataType = QueryDataType['getLatestDeliveryNumbers'];

const onError = getErrorLoggerFunction('getLatestDeliveryNumbers');

export const useGetLatestDeliveryNumbers = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_LATEST_DELIVERY_NUMBERS, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getLatestDeliveryNumbers || []);
        }
    }, [data]);

    return {
        latestDeliveryNumbers: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
