import type { FC } from 'react';
import React from 'react';
import type { MetaDataProps } from './metaHead';
import { MetaHead } from './metaHead';

type HeadType = {
    metaData?: MetaDataProps;
    slug: string;
};

export const Head: FC<HeadType> = (props) => {
    const { metaData, slug } = props;

    return (
        <>
            <MetaHead metaData={metaData} />
            <script type="application/javascript">
                {`
          var UC_UI_SUPPRESS_CMP_DISPLAY=${slug === 'impressum' || slug === 'datenschutz'};
          `}
            </script>
        </>
    );
};
