import { gql } from '@apollo/client';

export const RECOMMENDATION = gql`
    query Recommendation($ids: [String!]!, $maxResults: Float, $sid: String, $usePersonalization: Boolean) {
        recommendation(ids: $ids, maxResults: $maxResults, sid: $sid, usePersonalization: $usePersonalization) {
            bonus
            ean
            customerArticleNumber
            hasAccessories
            hasAlternatives
            id
            image
            isPromotion
            packagingSize
            position
            productCocontractor
            productName
            quantityUnit
            salesUnit
            scoreCardIndex
            sku
            supplierId
            supplierName
        }
    }
`;
