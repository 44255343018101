import { useAuth } from '@ab-core/hooks';
import { useGetAccounts } from '@ab-core/hooks-v2';
import type { UserExceedValueCheckProps } from './checks/userExceedValueCheck';
import { userExceedValueCheck } from './checks/userExceedValueCheck';
import { userHasPermissions } from './hasPermission';

export { userHasPermissions };

export const userIsEmployee = (): boolean => {
    const { user } = useAuth();

    return user?.activeAccount?.customerClassification === 'M9';
};

export const userHasForeignVatType = (): boolean => {
    const { user } = useAuth();

    return !!user?.activeAccount?.hasForeignVatType;
};

export const userHasPickupOnly = (): boolean => userHasPermissions('hasPickUpOnly');

export const userCanRequestOffer = (): boolean => userHasPermissions('userCanRequestOffer');

export const userCanOrder = (): boolean => userHasPermissions('canOrder');

export const hasReturnFee = (): boolean => userHasPermissions('hasReturnFee');

export const needsCostCenter = (): boolean => userHasPermissions('needsCostCenter');

export const needsCommission = (): boolean => userHasPermissions('needsCommision');

export const userOnlyOrderToProject = (): boolean => userHasPermissions('onlyOrderToProject');

export const seeProjectPrice = (): boolean => userHasPermissions('seeProjectPrice');

export const userExceedValue = (price: number): boolean =>
    userHasPermissions<UserExceedValueCheckProps>('maximumOrderValue', false, {
        action: userExceedValueCheck,
        payload: { price }
    });

export const hasDiversArticle = () => userHasPermissions('diversArticle');

export const manualDeliveryAddressEntry = () => userHasPermissions('noManualDeliveryAddressEntry');

export const userHasOnlySessionCarts = () => userHasPermissions('hasSessionCarts');

export const userCanSetGhost = () => userHasPermissions('canSetGhost');

export const userCompleteDelivery = (): boolean => userHasPermissions('completeDelivery');

export const userCanSeeBestPrice = () => userHasPermissions('seeBestPriceContact');

export const userCanSeeBestPriceOnProductCard = () => userHasPermissions('seeBestPriceOnProductCard');

export const isGhostAccess = () => {
    const { user } = useAuth();
    const accountId = user?.activeAccount?.accountId;
    const { accounts } = useGetAccounts({ accountIdForSort: accountId, skip: !accountId });

    if (!accountId || !accounts) {
        return false;
    }

    const activeAccount = accounts?.find((a) => a.accountId === accountId);

    return !!activeAccount?.isGhostAccess;
};

export const userCanSeeOutstandingReceivables = () => userHasPermissions('canSeeoutstandingReceivables');

export const userHasOnlySalesView = () => userHasPermissions('onlyHidePrices');

export const userCanSeeCredits = () => userHasPermissions('creditsSee');

export const userCanSeeTecselect = () => userHasPermissions('canSeeTecselect');

export const defaultCartIsSharedCart = () => userHasPermissions('defaultSharedCart');

export const userCanHidePrices = () => userHasPermissions('canHidePrices');

export const userHasOrderConfirmationList = () => userHasPermissions('email_orderconfirmlist');
