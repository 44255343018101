import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const PROJECT_OFFER = gql`
    query ProjectOffer($projectOfferId: String!) {
        projectOffer(projectOfferId: $projectOfferId) {
            ...OfferDetailFields
        }
    }
    ${FRAGMENTS.OFFER_DETAIL_FIELDS}
`;
