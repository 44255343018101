import { ROUTES } from '@ab-core/functions';
import { isLoggedIn } from '@ab-core/hooks';
import { navigationStore } from '@ab-core/store';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { FooterElement } from './components/footerElement';
import { FooterWrapper } from './index.styled';

export const FooterNavigation: FC = () => {
    const isUserLoggedIn = isLoggedIn();
    const shopNavigationOpen = navigationStore.getters.useShopNavigation();
    const navigationBar = navigationStore.getters.useUserNavigation();
    const collectionState = navigationStore.getters.useCollectionNavigation();

    const triggerProfile = () => {
        navigationStore.setters.setUserNavigation(!navigationBar);
        navigationStore.setters.setCollectionNavigation(false);
        navigationStore.setters.setShopNavigation(false);
    };

    const triggerCollection = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setCollectionNavigation(!collectionState);
        navigationStore.setters.setShopNavigation(false);
    };

    const triggerMenu = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setCollectionNavigation(false);
        navigationStore.setters.setShopNavigation(!shopNavigationOpen);
    };
    const navigateScanner = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setCollectionNavigation(false);
        navigationStore.setters.setShopNavigation(false);
        navigate(ROUTES.SCANNER);
    };

    return (
        <FooterWrapper>
            <FooterElement
                content={isUserLoggedIn ? 'Shop' : 'Menü'}
                iconName="Burger"
                onClick={triggerMenu}
                testId="open-shop-button-mobile"
            />
            {isUserLoggedIn && (
                <FooterElement
                    content="Sortiment"
                    iconName={collectionState ? 'Close' : 'Collection'}
                    onClick={triggerCollection}
                />
            )}
            {isUserLoggedIn && <FooterElement content="Scanner" iconName="Barcode" onClick={navigateScanner} />}

            <FooterElement
                iconName={isUserLoggedIn ? 'AccountLoggedIn' : 'Account'}
                content={isUserLoggedIn ? 'Profil' : 'Anmelden'}
                onClick={triggerProfile}
            />
        </FooterWrapper>
    );
};
