export const toggleSalesView = (accountNumber: string) => {
    if (!accountNumber) {
        return;
    }

    if (typeof window !== 'undefined') {
        const salesView = JSON.parse(localStorage.getItem(`ab_salesView_${accountNumber}`) || 'false');
        localStorage.setItem(`ab_salesView_${accountNumber}`, (!salesView).toString());
        window.dispatchEvent(new Event('storage_ab_salesView'));
    }
};

export const isSalesViewEnabled = (accountNumber: string): boolean =>
    JSON.parse(localStorage.getItem(`ab_salesView_${accountNumber}`) || 'false');
