import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Aktion: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
                stroke={color}
            />
            <path
                d="M10.7231 11.8725V10.6002H7.2466C7.22868 10.4031 7.22868 10.206 7.22868 9.99094C7.22868 9.75798 7.22868 9.54294 7.2466 9.30998H10.7231V8.03766H7.49748C7.89172 6.85494 8.75187 6.1023 10.1676 6.1023C11.5116 6.1023 12.2642 7.05206 12.2642 7.05206L13.214 5.69014C13.214 5.69014 12.2463 4.3999 10.1496 4.3999C7.64084 4.3999 6.17139 5.97686 5.61588 8.03766H4.2002V9.30998H5.40083C5.38291 9.54294 5.38291 9.7759 5.38291 9.99094C5.38291 10.206 5.38291 10.4031 5.40083 10.6002H4.2002V11.8725H5.59796C6.13556 13.9871 7.62292 15.5999 10.1496 15.5999C12.2463 15.5999 13.214 14.3097 13.214 14.3097L12.2642 12.9836C12.2642 12.9836 11.5116 13.9333 10.1676 13.9333C8.71603 13.9333 7.85588 13.1269 7.47956 11.8725H10.7231Z"
                fill={color}
            />
        </SVG>
    );
};
