export enum ROUTES {
    START = '/',
    NEWS = '/magazin',
    PROMOTION = '/promotion',
    SERVICES = '/services',
    PRIVACY = '/datenschutz',
    SHOP = '/mein-shop',
    LASTBOUGHTPRODUCTS = '/zuletzt-gekaufte-produkte',
    ORDERS = '/meine-belege',
    ORDER = '/beleg-details',
    PRODUCTPAGE = '/produkt',
    CATEGORY = '/kategorie',
    SEARCH = '/suche',
    OFFERS = '/meine-angebote',
    OFFERDETAILS = '/angebot-details',
    CART = '/warenkorb',
    CARTS = '/meine-warenkoerbe',
    ACCESSMANAGEMENT = '/zugangsverwaltung',
    CHECKOUT = '/bestelluebersicht',
    CHECKOUT_OFFER = '/angebot-bestelluebersicht',
    CHECKOUTSUCCESS = '/bestellung-erfolgreich',
    SHOPPINGLISTS = '/listen',
    SHOPPINGLIST = '/liste',
    SIGNUP = '/kunde-werden',
    SINGUPSUCCESS = '/registrierung-abgeschlossen',
    CABLEREEL = '/abholung-kabeltrommel',
    CABLEREELSUCCESS = '/kabeltrommel-gesendet',
    RETURNS = '/meine-retouren',
    RETURNCHECKIN = '/retoure-anmelden',
    RETURNCHECKOUT = '/retoure-checkout',
    RETURNSUCCESS = '/retoure-erfolgreich',
    USERLISTOVERVIEW = '/benutzer-uebersicht',
    USERCONFIGURATOR = '/benutzer-verwalten',
    OXOMICATALOGUES = '/kataloge',
    OXOMI_NAVIGATOR = '/navigator',
    SCANNER = '/scanner',
    FASTENTRY = '/schnellerfassung',
    BARCODELIST = '/barcode-liste',
    CHANGELOG = '/neuigkeiten',
    FAQ = '/faq',
    PROMOTIONALSHEET = '/aktionsblatt',
    ELBRIDGE = '/elbridge',
    CONFIGURATORS = '/konfiguratoren',
    CABLEDEALMARKET = '/kabelschnaeppchen',
    NEWSLETTER = '/newsletter-anmeldung',
    PRODUCT_COMPARE = '/produkt-vergleich',
    CREDIT_INFO = '/credits-info',
    CREDITS_DONATED_SUCCESS = '/credits-donated-success',
    HELP = '/hilfe',
    KANBAN = '/etiketten',
    ONBOARDING = '/onboarding',
    RECEIPT = '/wareneingang',
    SERVICE_DIRECTORY = '/leistungsverzeichnis',
    SERVICE_DIRECTORY_DETAIL = '/leistungsverzeichnis-detail'
}
