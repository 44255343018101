import type { Query, QueryIncomingGoodsArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { INCOMING_GOODS } from './gql/incomingGoods';

type QueryDataType = Pick<Query, 'incomingGoods'>;
type OutgoingDataType = QueryDataType['incomingGoods'];

const onError = getErrorLoggerFunction('incomingGoods');

export const useIncomingGoods = (deliveryNoteNumber = '') => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = !deliveryNoteNumber;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryIncomingGoodsArgs>(INCOMING_GOODS, {
        variables: {
            deliveryNoteNumber
        },
        onError,
        skip
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.incomingGoods || []);
        }
    }, [data]);

    return {
        incomingGoods: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
