import { gql } from '@apollo/client';

export const PROJECT_OFFERS = gql`
    query ProjectOffers {
        projectOffers {
            id
            version
            lastModifiedAt
            offerNumber
            orderType
            offerDate
            bindingPeriod
            priceBindingPeriod
            projectNumber
            project
            offerText
            customerId
            purchaser
            contactPerson
            costCenter
            desiredDeliveryDate
            completeDelivery
            singleInvoice
            priceDeliveryNote
            shippingInstructions
            displayShippingMethod
            modificationRule
            offerReferenceShippingAddress {
                id
                externalId
                primaryAddress
                name
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
                streetName
                postalCode
                city
                country
                defaultShippingInstructions
            }
            orderedSum {
                centAmount
                fractionDigits
                currencyCode
            }
        }
    }
`;
