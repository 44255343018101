import type { FC } from 'react';
import React from 'react';

export type MetaDataProps = {
    metaTitle?: string;
    metaDescription?: string;
    metaRobots?: string;
};

type MetaHeadType = {
    metaData?: MetaDataProps;
};

export const MetaHead: FC<MetaHeadType> = (props) => {
    const { metaData } = props;

    return (
        <>
            <meta httpEquiv="language" content="de" />
            <meta httpEquiv="cache-control" content="no-cache" />
            <title>{metaData?.metaTitle || 'Alexander Bürkle'}</title>
            <meta name="description" content={metaData?.metaDescription || 'Alexander Bürkle'} />
            <meta name="robots" content={metaData?.metaRobots || 'index, follow'} />
        </>
    );
};
