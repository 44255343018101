import { gql } from '@apollo/client';

export const GET_ORDER_CONFIRMATION_RECIPIENTS = gql`
    query GetOrderConfirmationRecipients {
        getOrderConfirmationRecipients {
            id
            accountId
            name
            mail
        }
    }
`;
