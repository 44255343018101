import { Icon } from '@ab-core/icon';
import React from 'react';

export const convertName = (name = ''): React.ReactNode => {
    switch (name) {
        case 'available':
            return 'Sofort Lieferbar';
        case 'purchase':
            return 'Bereits gekauft';
        case 'tecselectBonus':
            return (
                <>
                    <Icon size="SMALL_4" name="Tecselect" /> TECSELECT
                </>
            );
        case 'topProduct':
            return (
                <>
                    <Icon size="SMALL_4" name="Diamond" /> Top Produkt
                </>
            );
        case 'abakusBonus':
            return (
                <>
                    <Icon size="SMALL_4" name="Abakus" /> Abakus
                </>
            );
        case 'promotion':
            return (
                <>
                    <Icon size="SMALL_4" name="Aktion" /> Aktions-Artikel
                </>
            );
        case 'abakusPlusBonus':
            return (
                <>
                    <Icon size="SMALL_4" name="AbakusPlus" /> Abakus +
                </>
            );
        case 'fiveYearGuarantee':
            return (
                <>
                    <Icon size="SMALL_4" name="Five" /> 5-jahres Garantie
                </>
            );
        case 'alexgreen':
            return (
                <>
                    <Icon size="SMALL_4" name="Leaf" /> Alexgreen
                </>
            );
        case 'true':
            return 'Ja';
        case 'false':
        case '-':
            return 'Nein';
        case 'null':
            return '0';
        default:
            return name.replace(/[[\]]/g, '');
    }
};

export const encodeQueryValue = (text?: string) => {
    return text?.replaceAll('/', encodeURIComponent('/'));
};
