import type { Mutation, MutationCreateOrderConfirmationRecipientArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_ORDER_CONFIRMATION_RECIPIENT } from './gql/createOrderConfirmationRecipient';
import { GET_ORDER_CONFIRMATION_RECIPIENTS } from './gql/getOrderConfirmationRecipients';

type MutationDataType = Pick<Mutation, 'createOrderConfirmationRecipient'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOrderConfirmationRecipient');

const refetchQueries = [GET_ORDER_CONFIRMATION_RECIPIENTS];

export const useCreateOrderConfirmationRecipient = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createOrderConfirmationRecipient, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateOrderConfirmationRecipientArgs
    >(CREATE_ORDER_CONFIRMATION_RECIPIENT, {
        onError,
        refetchQueries
    });

    const outgoingOrderConfirmationRecipient = useCallback(
        (args: MutationCreateOrderConfirmationRecipientArgs['data']) => {
            createOrderConfirmationRecipient({ variables: { data: args } });
        },
        [createOrderConfirmationRecipient]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOrderConfirmationRecipient: outgoingOrderConfirmationRecipient,
        loading,
        response
    };
};
