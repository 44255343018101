import type { Mutation, MutationUpdateOrderConfirmationRecipientArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_ORDER_CONFIRMATION_RECIPIENTS } from './gql/getOrderConfirmationRecipients';
import { UPDATE_ORDER_CONFIRMATION_RECIPIENT } from './gql/updateOrderConfirmationRecipient';

type MutationDataType = Pick<Mutation, 'updateOrderConfirmationRecipient'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateOrderConfirmationRecipient');

const refetchQueries = [GET_ORDER_CONFIRMATION_RECIPIENTS];

export const useUpdateOrderConfirmationRecipient = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateOrderConfirmationRecipient, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateOrderConfirmationRecipientArgs
    >(UPDATE_ORDER_CONFIRMATION_RECIPIENT, {
        onError,
        refetchQueries
    });

    const outgoingUpdateOrderConfirmationRecipient = useCallback(
        (args: MutationUpdateOrderConfirmationRecipientArgs['data']) => {
            updateOrderConfirmationRecipient({ variables: { data: args } });
        },
        [updateOrderConfirmationRecipient]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateOrderConfirmationRecipient: outgoingUpdateOrderConfirmationRecipient,
        loading,
        response
    };
};
