import { getSessionId } from '@ab-core-functions/tracking';
import type { Query, QueryLastPurchasedV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { LAST_PURCHASED_V2 } from './gql/lastPurchasedV2';

type QueryDataType = Pick<Query, 'lastPurchasedV2'>;
type OutgoingDataType = QueryDataType['lastPurchasedV2'];

type IncomingDataType = {
    query?: string;
    page?: number;
};

const onError = getErrorLoggerFunction('lastPurchasedV2');

export const useLastPurchasedV2 = (props: IncomingDataType) => {
    const { query = '', page } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryLastPurchasedV2Args>(LAST_PURCHASED_V2, {
        variables: { query, page, sid: getSessionId() },
        onError
    });

    useEffect(() => {
        if (data?.lastPurchasedV2) {
            setOutgoingData(data.lastPurchasedV2);
        }
    }, [data]);

    return {
        lastPurchasedV2: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
