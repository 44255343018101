export const isShopApp = (): boolean => {
    if (typeof window === 'undefined') {
        return false;
    }

    // return !!window.navigator.userAgent.includes('Mobile Shop App');

    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};
