import type { Mutation, MutationSetIncomingGoodsInformationArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SET_INCOMING_GOODS_INFORMATION } from './gql/setIncomingGoodsInformation';

type MutationDataType = Pick<Mutation, 'setIncomingGoodsInformation'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('setIncomingGoodsInformation');

export const useSetIncomingGoodsInformation = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [setIncomingGoodsInformation, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSetIncomingGoodsInformationArgs
    >(SET_INCOMING_GOODS_INFORMATION, {
        onError
    });

    const outgoingSetIncomingGoodsInformation = useCallback(
        (
            deliveryNoteNumber: MutationSetIncomingGoodsInformationArgs['deliveryNoteNumber'],
            incomingGoodsPos: MutationSetIncomingGoodsInformationArgs['incomingGoodsPos']
        ) => {
            setIncomingGoodsInformation({ variables: { deliveryNoteNumber, incomingGoodsPos } });
        },
        [setIncomingGoodsInformation]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        setIncomingGoodsInformation: outgoingSetIncomingGoodsInformation,
        loading,
        response
    };
};
