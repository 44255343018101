import { gql } from '@apollo/client';

export const PRODUCTS_V2 = gql`
    query ProductsV2($skus: [String!], $limit: Int!, $offset: Int!) {
        productsV2(skus: $skus, limit: $limit, offset: $offset) {
            total
            offset
            count
            results {
                bonus {
                    isTecselect
                    isAbakus
                    isAbakusPlus
                    isPromotion
                    isTopProduct
                }
                customerArticleNumber
                ean
                hasAccessories
                hasAlternatives
                id
                thumbnail
                packagingSize
                procuredProduct
                productCocontractor
                productName
                quantityUnit
                salesUnit
                sku
                supplierId
                supplierProductNumber
                isOnlineAvailable
                reachInfo
                relationType
                weightReference
                scoreCardIndex
                isUnqualifiedContractProduct
                customTariffNumber
                description
                slug
                supplierProductLink
                weight
                fiveYearGuarantee
            }
        }
    }
`;
