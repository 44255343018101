import { gql } from '@apollo/client';

export const PRODUCT_BY_IDS_V2 = gql`
    query ProductsByIdsV2($ids: [String!]) {
        productsByIdsV2(ids: $ids) {
            total
            offset
            count
            results {
                bonus {
                    isTecselect
                    isAbakus
                    isAbakusPlus
                    isPromotion
                    isTopProduct
                }
                categories {
                    id
                    externalId
                    slug
                    name
                    description
                    stagedProductCount
                    orderHint
                    parent {
                        id
                    }
                    custom {
                        customFieldsRaw {
                            name
                            value
                        }
                    }
                    metaTitle
                    metaDescription
                }
                customerArticleNumber
                ean
                hasAccessories
                hasAlternatives
                id
                thumbnail
                packagingSize
                procuredProduct
                productCocontractor
                productName
                quantityUnit
                salesUnit
                sku
                supplierId
                supplierProductNumber
                isOnlineAvailable
                reachInfo
                relationType
                weightReference
                scoreCardIndex
                isUnqualifiedContractProduct
                images {
                    url
                    label
                }
                customTariffNumber
                description
                relatedProducts {
                    alternativesEnventa
                    similarProducts
                    baseProducts
                    necessaryAccessories
                    necessarySelections
                    parts
                    accessoriesProducts
                    spareParts
                    replacements
                }
                productDocumentsDeha {
                    name
                    link
                }
                productFeatures {
                    featureName
                    featureValueScoped {
                        minValue
                        maxValue
                    }
                    featureValues
                    unit
                    version
                }
                slug
                supplierProductLink
                weight
                fiveYearGuarantee
            }
        }
    }
`;
