import { datadogLogs } from '@datadog/browser-logs';
import { DebugModal } from './debugModal';

type LoggerType<T> = {
    title: string;
    infos?: T;
    error?: Error;
};

datadogLogs.init({
    clientToken: process.env.GATSBY_DD_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: false,
    service: 'frontend.ab-core',
    env: process.env.GATSBY_DD_ENV
});

export const logger = {
    debug: <T>(props: LoggerType<T>) => {
        datadogLogs.logger.debug(props.title, { _infos: props.infos as object });
    },
    info: <T>(props: LoggerType<T>) => {
        datadogLogs.logger.info(props.title, { _infos: props.infos as object });
    },
    warn: <T>(props: LoggerType<T>) => {
        datadogLogs.logger.warn(props.title, { _infos: props.infos as object });
    },
    error: <T>(props: LoggerType<T>) => {
        const errorLoggingInformation = JSON.parse(localStorage.getItem('errorLoggingInformation') || '{}');

        datadogLogs.logger.error(
            props.title,
            { _infos: { ...errorLoggingInformation, ...(props.infos as object) } },
            props.error
        );
    }
};

export { DebugModal };
