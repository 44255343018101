import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import { BasicInput } from '@ab-core/input';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import React, { useEffect, useMemo, useState } from 'react';
import type { DropdownItemProps } from './components/dropdownItem';
import { DropdownItem } from './components/dropdownItem';
import { DropdownList } from './components/dropdownList';
import { Dropdown, DropdownContainer, IconWrapper, Label, PseudoElementLine } from './styled';

export type BasicDropdownItemProps = Omit<DropdownItemProps, 'onItemSelect'>;

export type BasicDropdownItemsProps = Array<BasicDropdownItemProps>;

export type BasicDropdownProps = React.ComponentPropsWithoutRef<'div'> &
    WithTestId & {
        items?: BasicDropdownItemsProps;
        label?: string;
        selectedValue?: string;
        disabled?: boolean;
        onItemChange?: (item?: BasicDropdownItemProps) => void;
        error?: boolean;
        searchable?: boolean;
        clearable?: boolean;
    };

export const BasicDropdown = React.forwardRef<HTMLInputElement, BasicDropdownProps>((props, ref) => {
    const {
        label,
        error,
        disabled,
        items,
        selectedValue,
        onItemChange,
        onMouseLeave,
        testId,
        searchable,
        clearable,
        ...rest
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<BasicDropdownItemProps | undefined>(undefined);
    const [filterValue, setFilterValue] = useState('');

    const setItemActive = (item: BasicDropdownItemProps) => {
        if (item.disabled) {
            return;
        }

        if (item === selectedItem) {
            return;
        }

        setFilterValue('');

        setSelectedItem(item);
        setIsOpen(false);

        if (onItemChange) {
            onItemChange(item);
        }
    };

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOnMouseLeave: BasicDropdownProps['onMouseLeave'] = (e) => {
        if (onMouseLeave) {
            onMouseLeave(e);
        }

        setIsOpen(false);
    };

    useEffect(() => {
        const newActiveItem = items?.find((item) => item.value === selectedValue);
        setSelectedItem(newActiveItem);
    }, [selectedValue]);

    const dropdownClasses = getClassNames({
        prefix: 'dropdown',
        modifier: { error, disabled, 'has-selected-item': !!selectedItem }
    });

    const labelClass = getClassNames({
        prefix: 'dropdown-label',
        modifier: { error, disabled }
    });

    const iconClass = getClassNames({
        prefix: 'dropdown-icon',
        modifier: { error, disabled }
    });

    const filteredItems = useMemo(
        () =>
            items?.filter(
                (item) =>
                    item.value.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item.label?.toString().toLowerCase().includes(filterValue.toLowerCase())
            ),
        [filterValue, items]
    );

    const clearIconClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setSelectedItem(undefined);

        if (onItemChange) {
            onItemChange(undefined);
        }
    };

    return (
        <DropdownContainer onMouseLeave={handleOnMouseLeave} {...rest} ref={ref}>
            <Dropdown className={dropdownClasses} onClick={toggleDropdown} {...getTestIdProp(testId)}>
                {selectedItem?.label || label}
                {clearable && selectedItem && (
                    <div onClick={clearIconClicked} {...getTestIdProp(`${testId}-clear-icon`)}>
                        <Icon name="Close" color="gray50" size="SMALL_4" />
                    </div>
                )}
            </Dropdown>
            {label && selectedItem && (
                <Label className={labelClass}>
                    <PseudoElementLine />
                    {label}
                </Label>
            )}
            <IconWrapper>
                <Icon className={iconClass} name="ChevronUp" size="SMALL_3" rotation={isOpen ? 0 : 180} />
            </IconWrapper>
            {isOpen && (
                <DropdownList>
                    {searchable && (
                        <BasicInput
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                            autoFocus
                            {...getTestIdProp(`${testId}-search-field`)}
                        />
                    )}
                    {(filteredItems?.length || 0) > 0 ? (
                        filteredItems?.map((item) => (
                            <DropdownItem
                                key={item.id}
                                {...item}
                                isSelected={item.value === selectedItem?.value}
                                onClick={() => setItemActive(item)}
                            />
                        ))
                    ) : (
                        <DropdownItem disabled label="Kein Eintrag gefunden" value="0" />
                    )}
                </DropdownList>
            )}
        </DropdownContainer>
    );
});
