import { userHasOnlySalesView } from '@ab-core-functions/user-permission';
import { isSalesViewEnabled } from '@ab-core/functions/salesView';
import type { Query, QueryPositionPriceObjectsArgs } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { POSITION_PRICE_OBJECTS } from './gql/positionPriceObjects';

type QueryDataType = Pick<Query, 'positionPriceObjects'>;
type OutgoingDataType = QueryDataType['positionPriceObjects'];

const onError = getErrorLoggerFunction('positionPriceObjects');

// Hinweis: die EDV nimmt maximal 50 items auf einmal an
export const usePositionPriceObjects = (props: QueryPositionPriceObjectsArgs) => {
    const { hidePrice, positions, projectId } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);
    const [isSkip, setIsSkip] = useState(true);

    const { user } = useAuth();
    const accountNumber = user?.activeAccount?.accountNumber || '';
    const onlySalesView = userHasOnlySalesView();
    const isSalesView = onlySalesView || isSalesViewEnabled(accountNumber);

    useEffect(() => {
        setIsSkip(!positions || positions.length === 0 || !user);
    }, [positions, user]);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryPositionPriceObjectsArgs>(
        POSITION_PRICE_OBJECTS,
        {
            variables: {
                hidePrice: hidePrice || isSalesView,
                positions,
                projectId
            },
            skip: isSkip,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.positionPriceObjects);
        }
    }, [data]);

    return {
        positionPriceObjects: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
