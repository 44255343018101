import { gql } from '@apollo/client';

export const LAST_PURCHASED_V2 = gql`
    query LastPurchasedV2($query: String!, $page: Float, $sid: String) {
        lastPurchasedV2(query: $query, page: $page, sid: $sid) {
            results {
                bonus
                ean
                customerArticleNumber
                hasAccessories
                hasAlternatives
                id
                image
                isPromotion
                packagingSize
                position
                productCocontractor
                productName
                quantityUnit
                salesUnit
                scoreCardIndex
                sku
                supplierId
                supplierName
            }
            pageCount
            currentPage
            count
            total
        }
    }
`;
