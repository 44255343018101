import { getSessionId } from '@ab-core-functions/tracking';
import type { Query, QueryRecommendationArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { RECOMMENDATION } from './gql/recommendation';

type QueryDataType = Pick<Query, 'recommendation'>;
type OutgoingDataType = QueryDataType['recommendation'];

type IncomingDataType = {
    ids: Array<string>;
    maxResults?: number;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('recommendation');

export const useRecommendation = (props: IncomingDataType) => {
    const { ids, skip = false, maxResults } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryRecommendationArgs>(RECOMMENDATION, {
        variables: { ids, maxResults, sid: getSessionId(), usePersonalization: false },
        onError,
        skip
    });

    useEffect(() => {
        if (data?.recommendation) {
            setOutgoingData(data.recommendation);
        }
    }, [data]);

    return {
        recommendation: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
